<template>
    <v-navigation-drawer width="100%" height="calc(100% - 2rem)" fixed app floating :expand-on-hover="mini"
        :value="drawer" :right="$vuetify.rtl" class="my-2 ms-2 border-radius-xl"
        :class="!$vuetify.breakpoint.mobile ? '' : 'bg-white'" :data-color="sidebarColor" :data-theme="sidebarTheme">

        <div class="position-relative min-vh-100"
            :style="`background:  url(${require('../assets/img/menu/bg.png')}) no-repeat center center fixed; background-size: cover; background-color: transparent!important;`">
            <span class="mask border-radius-xl opacity-1"></span>
            <v-list-item-group>
                <div v-for="(item, i) in items" :key="i">
                    <v-list-item link :to="item.link" class="pb-1 mx-1 no-default-hover py-1" :ripple="false"
                        active-class="item-active" v-if="!item.external" :disabled="item.isLinkDisabled">
                        <v-list-item-icon class="me-1 align-center">
                            <!--<i class="material-icons-round opacity-10">{{ item.action }}</i>-->
                            <i class="material-icons-round opacity-10"><v-img :src="item.image"
                                    style="height: 24px; width: 24px;"></v-img></i>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title v-text="item.title" v-if="item.title != '登出'"
                                class="ms-1"></v-list-item-title>
                            <v-list-item-title v-text="item.title" @click="logout" v-else></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                </div>
            </v-list-item-group>
            <hr class="horizontal my-2" :class="sidebarTheme == 'dark' ? 'light' : 'dark'" />
            <v-list-item-group>
                <div v-for="(item, i) in itemsDocs" :key="i">
                    <v-list-item link :to="item.link" class="pb-1 mx-1 no-default-hover py-1" :ripple="false"
                        active-class="item-active" v-if="!item.external" :disabled="item.isLinkDisabled">
                        <v-list-item-icon class="me-1 align-center">
                            <!--<i class="material-icons-round opacity-10">{{ item.action }}</i>-->
                            <i class="material-icons-round opacity-10"><v-img :src="item.image"
                                    style="height: 24px; width: 24px;"></v-img></i>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title v-text="item.title" class="ms-1"></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </div>
            </v-list-item-group>
        </div>

        <!--<v-list nav dense>
            <v-list-group :ripple="false" append-icon="fas fa-angle-down" v-model="active" class="pb-1 mx-2" active-class="item-active">
                <template v-slot:activator>
                    <v-avatar size="30" class="my-3 ms-2">
                        <img src="@/assets/img/sketch.jpg" alt="Brooklyn" />
                    </v-avatar>
                    <v-list-item-content>
                        <v-list-item-title class="ms-2 ps-1 font-weight-light">
                            {{name}}
                        </v-list-item-title>
                    </v-list-item-content>
                </template>
<v-list-item :ripple="false" link class="mb-1 no-default-hover py-2" v-for="child in userInfo" :key="child.title"
    :to="child.link">
    <span class="v-list-item-mini ms-0 font-weight-light text-center w-20" v-text="child.prefix"></span>
    <v-list-item-content class="ms-2 ps-1" v-if="!child.items">
        <v-list-item-title v-text="child.title" v-if="child.title != '登出'">
        </v-list-item-title>
        <v-list-item-title v-text="child.title" @click="logout" v-else>
        </v-list-item-title>
    </v-list-item-content>
    <v-list-item-content class="ms-1 ps-1 py-0" v-if="child.items">
        <v-list-group prepend-icon="" :ripple="false" sub-group no-action v-model="child.active">
            <template v-slot:activator>
                                <v-list nav dense class="pa-0">
                                    <v-list-group :ripple="false" append-icon="fas fa-angle-down me-auto ms-1" active-class="item-active" class="mb-0">
                                        <template v-slot:activator class="mb-0">
                                            <v-list-item-content class="py-0">
                                                <v-list-item-title v-text="child.title"></v-list-item-title>
                                            </v-list-item-content>
                                        </template>
        </v-list-group>
        </v-list>
        </template>
        <v-list-item v-for="child2 in child.items" :ripple="false" :key="child2.title" :to="child2.link"
            @click="listClose($event)">
            <span class="v-list-item-mini" v-text="child2.prefix"></span>
            <v-list-item-content>
                <v-list-item-title v-text="child2.title"></v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        </v-list-group>
    </v-list-item-content>
</v-list-item>
</v-list-group>
</v-list>-->

        <!--<hr class="horizontal mb-3" :class="sidebarTheme == 'dark' ? 'light' : 'dark'" />-->
        <!--<v-list nav dense>
            <v-list-group :ripple="false" v-for="item in items" :key="item.title" v-model="item.active" append-icon="fas fa-angle-down" class="pb-1 mx-2" active-class="item-active">
                <template v-slot:activator>
                    <v-list-item-icon class="me-2 align-center">
                        <i class="material-icons-round opacity-10">{{ item.action }}</i>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title v-text="item.title" class="ms-1"></v-list-item-title>
                    </v-list-item-content>
                </template>
                <v-list-item :ripple="false" link class="mb-1 no-default-hover px-0" :class="child.items ? 'has-children' : ''" v-for="child in item.items" :key="child.title" :to="child.link">
                    <div class="w-100 d-flex align-center pa-2 border-radius-lg">
                        <span class="v-list-item-mini" v-text="child.prefix"></span>
                        <v-list-item-content class="ms-6 ps-1" v-if="!child.items">
                            <v-list-item-title v-text="child.title" @click="listClose($event)"></v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-content class="ms-6 ps-1 py-0" v-if="child.items">
                            <v-list-group prepend-icon="" :ripple="false" sub-group no-action v-model="child.active">
                                <template v-slot:activator>
                                    <v-list nav dense class="pa-0">
                                        <v-list-group :ripple="false" append-icon="fas fa-angle-down me-auto ms-1" active-class="item-active" class="mb-0">
                                            <template v-slot:activator class="mb-0">
                                                <v-list-item-content class="py-0">
                                                    <v-list-item-title v-text="child.title"></v-list-item-title>
                                                </v-list-item-content>
                                            </template>
                                        </v-list-group>
                                    </v-list>
                                </template>
                                <v-list-item v-for="child2 in child.items" :ripple="false" :key="child2.title" :to="child2.link" @click="listClose($event)">
                                    <span class="v-list-item-mini" v-text="child2.prefix"></span>
                                    <v-list-item-content>
                                        <v-list-item-title v-text="child2.title"></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-group>
                        </v-list-item-content>
                    </div>
                </v-list-item>
            </v-list-group>
            <v-list-group :ripple="false" v-for="item in itemsPages" :key="item.title" v-model="item.active" append-icon="fas fa-angle-down" class="pb-1 mx-2" active-class="item-active">
                <template v-slot:activator>
                    <v-list-item-icon class="me-2 align-center">
                        <i class="material-icons-round opacity-10">{{ item.action }}</i>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title v-text="item.title" class="ms-1"></v-list-item-title>
                    </v-list-item-content>
                </template>
                <v-list-item :ripple="false" link class="mb-1 no-default-hover px-0" :class="child.items ? 'has-children' : ''" v-for="child in item.items" :key="child.title" :to="child.link">
                    <v-list-item-content class="ps-4" v-if="!child.items">
                        <div class="d-flex align-items-center pa-2">
                            <span class="v-list-item-mini ms-0" v-text="child.prefix"></span>
                            <v-list-item-title class="ms-6" v-text="child.title" @click="listClose($event)"></v-list-item-title>
                        </div>
                    </v-list-item-content>
                    <v-list-item-content class="py-0" v-if="child.items">
                        <v-list-group prepend-icon="" :ripple="false" sub-group no-action v-model="child.active">
                            <template v-slot:activator>
                                <v-list nav dense class="py-2 ps-5 pe-2">
                                    <v-list-group :ripple="false" append-icon="fas fa-angle-down me-auto ms-1" active-class="item-active" class="mb-0">
                                        <template v-slot:activator class="mb-0">
                                            <div class="w-100 d-flex align-center">
                                                <span class="v-list-item-mini ms-1" v-text="child.prefix"></span>
                                                <v-list-item-content class="py-0 ms-4">
                                                    <v-list-item-title class="ms-2" v-text="child.title"></v-list-item-title>
                                                </v-list-item-content>
                                            </div>
                                        </template>
                                    </v-list-group>
                                </v-list>
                            </template>
                            <v-list-item v-for="child2 in child.items" :ripple="false" :key="child2.title" :to="child2.link" @click="listClose($event)" class="px-0">
                                <v-list-item-content>
                                    <div class="d-flex align-items-center pa-2">
                                        <span class="v-list-item-mini" v-text="child2.prefix"></span>
                                        <v-list-item-title v-text="child2.title" class="ms-6"></v-list-item-title>
                                    </div>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-group>
                    </v-list-item-content>
                </v-list-item>
            </v-list-group>
        </v-list>-->
        <!--<v-card class="pa-0 border-radius-lg mt-7 mb-9 mx-4" :style="`background-image: url(${require('../assets/img/curved-images/white-curved.jpeg')}); background-size: cover;`">
            <span class="mask opacity-8 border-radius-lg" :class="`bg-gradient-` + sidebarColor"></span>
        </v-card>-->
    </v-navigation-drawer>
</template>
<script>
import Cookie from 'js-cookie';
import axios from 'axios';
import { userData } from "@/apis/userService";
import { jobGetStatus } from "@/apis/jobService";

let config = {
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
        "Content-Type": "application/json;",
    }
}

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

const Base64 = require("js-base64").Base64;

export default {
    name: "drawer",
    props: {
        drawer: {
            type: Boolean,
            default: null,
        },
        sidebarColor: {
            type: String,
            default: "success",
        },
        sidebarTheme: {
            type: String,
            default: "dark",
        },
    },
    data: () => ({
        name: "",
        role: "",
        active: true,
        mini: false,
        togglerActive: true,
        robot_enabled: 0,
        thirdLevelSimple: [
            "Third level menu",
            "Just another link",
            "One last link",
        ],
        items: [{
            action: "account_box",
            title: "首頁",
            link: "/homepage",
            image: require("@/assets/img/menu/01.png"),
            isLinkDisabled: false,
        }, {
            action: "account_box",
            title: "會員資訊",
            link: "/profile",
            image: require("@/assets/img/menu/02.png"),
            isLinkDisabled: false,
        },
        {
            action: "attractions",
            title: "開獎專區",
            link: "/lottery-area",
            image: require("@/assets/img/menu/03.png"),
            isLinkDisabled: false,
        },
        {
            action: "headset_mic",
            title: "服務說明",
            link: "/service-description",
            image: require("@/assets/img/menu/04.png"),
            isLinkDisabled: false,
        },
        {
            action: "document_scanner",
            title: "免責聲明",
            link: "/disclaimer",
            image: require("@/assets/img/menu/05.png"),
            isLinkDisabled: false,
        },
        {
            action: "logout",
            title: "登出",
            prefix: "",
            image: require("@/assets/img/menu/06.png"),
            isLinkDisabled: false,
        },
        ],
        //userInfo: [],
        itemsDocs: [
            {
                action: "smart_toy",
                title: "AI機器人管理",
                link: "/robot-agent-overview",
                image: require("@/assets/img/menu/07.png"),
                isLinkDisabled: true,
            },
            {
                action: "paid",
                title: "賓果EX",
                link: "/games/new-bingo",
                image: require("@/assets/img/menu/08.png"),
                isLinkDisabled: false,
                //external: true,
            },
            {
                action: "paid",
                title: "WG彩票",
                link: "/games/new-wg-lottery-ar",
                image: require("@/assets/img/menu/10.png"),
                isLinkDisabled: false,
                //external: true,
            },
            /*
            {
                action: "paid",
                title: "539演算",
                link: "/games/new-bingo",
                image: require("@/assets/img/menu/09.png"),
                isLinkDisabled: false,
                //external: true,
            },
            {
                action: "paid",
                title: "WG彩票",
                link: "/games/new-bingo",
                image: require("@/assets/img/menu/10.png"),
                isLinkDisabled: false,
                //external: true,
            },
            */
        ],
        /*
        items: [{
            action: "dashboard",
            active: false,
            title: "業績與獎金",
            items: [{
                    title: "經銷獎金",
                    prefix: "",
                    link: "/pages/applications/bonus",
                },
                {
                    title: "組織階梯表",
                    prefix: "",
                    link: "/pages/pages/account/groups",
                },
            ],
        }, ],
        */

        /*
        itemsPages: [

            {
                action: "dashboard",
                active: true,
                title: "業績與獎金",
                items: [{
                        title: "經銷獎金",
                        prefix: "",
                        link: "/pages/applications/bonus",
                    },
                    {
                        title: "組織階梯表",
                        prefix: "",
                        link: "/pages/pages/account/groups",
                    },

                ],
            },
            {
                action: "shopping_basket",
                active: false,
                title: "訂單查詢",
                items: [{
                    title: "個人訂購",
                    prefix: "",
                    link: "/pages/ecommerce/orders/list/own",
                }, ],
            },
            */
        /*
        {
            action: "manage",
            active: false,
            title: "經銷管理",
            items: [{
                title: "經銷商查詢",
                prefix: "",
                link: "/pages/pages/account/dealer",
            }, ],
        },
        
        {
            action: "image",
            active: false,
            title: "管理",
            items: [{
                    title: "獎金報表",
                    prefix: "",
                    link: "/pages/ecommerce/orders/list",
                },
                {
                    title: "會員管理",
                    prefix: "",
                    link: "/pages/applications/member-manage",
                },
            ],
        },
        
    ],
    */

    }),
    mounted() {
        const token = Cookie.get('auth')

        console.log(token)
        console.log(typeof (token))
        console.log((token === 'null') || (typeof (token) === "undefined"))
        if ((token === 'null') || (typeof (token) === "undefined")) {
            //this.$router.push('/homepage');
            console.log("token is null")

            // 當沒有登入隱藏會員資訊功能
            this.items[1].isLinkDisabled = true

            // 當沒有登入隱藏登出功能
            this.items[5].isLinkDisabled = true

            // 當沒有登入AI機器人相關功能不能有作用
            for (let i = 0; i < this.itemsDocs.length; i++) {
                this.itemsDocs[i].isLinkDisabled = true
            }

        } else {
            // 當登入顯示會員資訊功能
            this.items[1].isLinkDisabled = false

            // 當登入顯示登出功能
            this.items[5].isLinkDisabled = false

            const tokenParts = token.split('.')
            console.log(tokenParts)
            const body = JSON.parse(atob(tokenParts[1]))
            console.log(body)
            console.log(body.sub)
            console.log(body.iat)
            console.log(body.exp)

            const iatDate = new Date(body.iat * 1000);
            console.log(iatDate)
            const expDate = new Date(body.exp * 1000);
            console.log(expDate)

            const nowDate = new Date()
            console.log(nowDate)

            // Token 到期登出
            if (nowDate > expDate) {
                this.logout();
            }

            this.userId = body.sub

            userData(this.userId)
                .then(response => {
                    this.repsData = response.data;
                    console.log(this.repsData)
                    this.robot_enabled = this.repsData.robot_enabled
                    this.email = this.repsData.email

                    // 賓果EX特殊處理，只有特定帳號可以看到 BingoBingo 設定功能頁面
                    /*
                    for (let i = 0; i < this.itemsDocs.length; i++) {
                        if (this.itemsDocs[i].title === "賓果EX") {
                            if (this.email === "justin-1380@hotmail.com") {
                                this.itemsDocs[i].link = "/games/new-bingo-bingo"
                            }
                        }
                    }
                    */

                    if (this.robot_enabled === 0) {
                        for (let i = 0; i < this.itemsDocs.length; i++) {
                            this.itemsDocs[i].isLinkDisabled = true
                        }

                    } else {
                        // 當AI機器人啟動後所有遊戲設定不能設定，反之沒有機器人都可以設定
                        jobGetStatus(this.userId)
                            .then((response) => {
                                console.log(response);
                                this.repsMessage = response.data.message;
                                const jobStatus = response.data.job_status;
                                console.log("jobStatus");
                                console.log(jobStatus);

                                if (typeof jobStatus === 'undefined') {
                                    for (let i = 0; i < this.itemsDocs.length; i++) {
                                        if (i === 0) {
                                            this.itemsDocs[i].isLinkDisabled = true
                                        } else {
                                            this.itemsDocs[i].isLinkDisabled = false
                                        }
                                    }
                                } else {
                                    for (let i = 0; i < this.itemsDocs.length; i++) {
                                        if (i === 0) {
                                            this.itemsDocs[i].isLinkDisabled = false
                                        } else {
                                            this.itemsDocs[i].isLinkDisabled = true
                                        }
                                    }
                                }
                            })
                            .catch(function (error) {
                                // 請求失敗處理
                                console.log(error);
                            });

                    }
                })
                .catch(error => { // 请求失败处理
                    console.log(error);
                });

        }



    },
    computed: {
        receiveAppBarTriggerEvent: function () {
            return this.$store.getters.pageDrawerData
        }
    },
    watch: {
        receiveAppBarTriggerEvent(val) {
            console.log("receiveAppBarTriggerEvent = " + val.trigger_update_drawer)
            if (val.trigger_update_drawer) {
                this.checkMenuStatus()
            }
        }
    },
    methods: {
        logout() {
            Cookie.set('auth', null)
            localStorage.setItem("admin_token", null)
            localStorage.removeItem("admin_token")
            setTimeout(() => {
                //this.$router.push('/homepage');
                this.$router.go();
            }, 100)
        },

        checkMenuStatus() {
            const token = Cookie.get('auth')

            console.log(token)
            console.log(typeof (token))
            console.log((token === 'null') || (typeof (token) === "undefined"))
            if ((token === 'null') || (typeof (token) === "undefined")) {
                this.$router.push('/homepage');

            } else {
                const tokenParts = token.split('.')
                console.log(tokenParts)
                const body = JSON.parse(atob(tokenParts[1]))
                console.log(body)
                console.log(body.sub)
                console.log(body.iat)
                console.log(body.exp)

                const iatDate = new Date(body.iat * 1000);
                console.log(iatDate)
                const expDate = new Date(body.exp * 1000);
                console.log(expDate)

                const nowDate = new Date()
                console.log(nowDate)

                // Token 到期登出
                if (nowDate > expDate) {
                    this.logout();
                }

                this.userId = body.sub

                userData(this.userId)
                    .then(response => {
                        this.repsData = response.data;
                        console.log(this.repsData)
                        this.robot_enabled = this.repsData.robot_enabled
                        this.email = this.repsData.email

                        // 賓果EX特殊處理，只有特定帳號可以看到 BingoBingo 設定功能頁面
                        /*
                        for (let i = 0; i < this.itemsDocs.length; i++) {
                            if (this.itemsDocs[i].title === "賓果EX") {
                                if (this.email === "justin-1380@hotmail.com") {
                                    this.itemsDocs[i].link = "/games/new-bingo-bingo"
                                }
                            }
                        }
                        */

                        if (this.robot_enabled === 0) {
                            for (let i = 0; i < this.itemsDocs.length; i++) {
                                this.itemsDocs[i].isLinkDisabled = true
                            }

                        } else {
                            // 當AI機器人啟動後所有遊戲設定不能設定，反之沒有機器人都可以設定
                            jobGetStatus(this.userId)
                                .then((response) => {
                                    console.log(response);
                                    this.repsMessage = response.data.message;
                                    const jobStatus = response.data.job_status;
                                    console.log("jobStatus");
                                    console.log(jobStatus);

                                    if (typeof jobStatus === 'undefined') {
                                        for (let i = 0; i < this.itemsDocs.length; i++) {
                                            if (i === 0) {
                                                this.itemsDocs[i].isLinkDisabled = true
                                            } else {
                                                this.itemsDocs[i].isLinkDisabled = false
                                            }
                                        }
                                    } else {
                                        for (let i = 0; i < this.itemsDocs.length; i++) {
                                            if (i === 0) {
                                                this.itemsDocs[i].isLinkDisabled = false
                                            } else {
                                                this.itemsDocs[i].isLinkDisabled = true
                                            }
                                        }
                                    }
                                })
                                .catch(function (error) {
                                    // 請求失敗處理
                                    console.log(error);
                                });

                        }
                    })
                    .catch(error => { // 请求失败处理
                        console.log(error);
                    });
            }
        },
    },
};
</script>