<template>
  <v-container class="py-3 px-0">
    <div class="position-relative"
      :style="`background: url(${require('@/assets/img/homepage.jpg')}) top center fixed; background-size: contain; height: 100vh;`">
      <v-row>
        <v-col cols="12">
          <v-card :style="`background-color: transparent!important;`">
            <div class="px-1 mb-2">
              <v-row>
                <v-col cols="12" lg="3" md="6">
                  <marquee-text :duration="30" class="text-xl text-typo">
                    {{ msg }}
                  </marquee-text>
                </v-col>
              </v-row>
            </div>
            <div v-if="!isLogin" class="position-relative"
              :style="`background: url(${require('@/assets/img/homepage-login.jpg')}) center; background-size: contain; height: 6vh;`"
              @click="login">
              <v-row>
                <v-col cols="12" lg="3" md="6">
                </v-col>
              </v-row>
            </div>
            <div class="px-4 py-4">
              <v-row>
                <v-col cols="6" v-for="card in cards" :key="card.title" lg="3" md="6">
                  <v-card :style="`background-color: transparent!important;`"
                    @click="verifyRobotOperation(card.nextPageLink)">
                    <div class="position-relative">
                      <!--<router-link :to="card.nextPageLink" class="d-block shadow-xl border-radius-xl">-->
                      <v-img :src="card.image" class="shadow border-radius-xl"></v-img>
                      <!--</router-link>-->
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </div>
            <div class="footer-buttons">
              <button @click="action1">
                <v-img class="fill-width fill-height" style="width: 50px; height: 50px;"
                  src="../../../assets/img/homepage-home.png">
                </v-img>
              </button>
              <v-divider vertical></v-divider>
              <button @click="action2">
                <v-img class="fill-width fill-height" style="width: 50px; height: 50px;"
                  src="../../../assets/img/homepage-disclaimer.png">
                </v-img>
              </button>
              <v-divider vertical></v-divider>
              <button @click="action3">
                <v-img class="fill-width fill-height" style="width: 50px; height: 50px;"
                  src="../../../assets/img/homepage-service-description.png">
                </v-img>
              </button>
              <v-divider vertical></v-divider>
              <button @click="action4">
                <v-img class="fill-width fill-height" style="width: 50px; height: 50px;"
                  src="../../../assets/img/homepage-lottery-area.png">
                </v-img>
              </button>
              <v-divider vertical></v-divider>
              <button @click="action5">
                <v-img class="fill-width fill-height" style="width: 50px; height: 50px;"
                  src="../../../assets/img/homepage-profile.png">
                </v-img>
              </button>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
import Cookie from 'js-cookie';
import axios from 'axios';
import { userDataUpdate, userData, userSignUp, userLogin } from "@/apis/userService";
import { jobGetStatus } from "@/apis/jobService";
import MarqueeText from 'vue-marquee-text-component'

let config = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
    "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
    "Content-Type": "application/json;",
  }
}

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

Vue.use(VueSweetalert2);

const Base64 = require("js-base64").Base64;

const client_id = "2005673202"; // yourClient_id 請設置為實際Channel ID 值
const redirect_uri = process.env.VUE_APP_REDIRECT_URI; // yourURI 請設置為實際Line developer 設定的重新導向網址
const bot_prompt = "normal";
const client_secret = "b20be1465398844207a50ffd27a041e0"; // yourClient_secret 請設置為實際Channel Secret 值
const line_notify_client_id = "JzeUPDYqbK770jo6ngZ8MQ";
const line_notify_client_secret = "S4tqOz1pxCyAvldOdbPQDsDMr0SEQRbSF27q3LFtTPz";

(async () => {
  try {
    const token = Cookie.get("auth");

    if (token === "null" || typeof token === "undefined") {
      console.log(token);
    } else {
      const tokenParts = token.split(".");
      console.log(tokenParts);
      const body = JSON.parse(atob(tokenParts[1]));
      console.log(body);
      console.log(body.sub);
      const userId = body.sub
    }

    // 使用 window.location.search 和 urlParams 獲取當前網頁 URL 中的查詢參數
    //const params = window.location.searchParams;
    const params = new URL(location.href).searchParams;
    console.log("params");
    console.log(params.size);

    if (params.size === 0) {
      return;
    }

    const code = params.get("code");
    console.log(code);

    const state = params.get("state");
    console.log(state);

    const qs = require("qs");

    /*
      使用 Axios 發送 HTTP POST 請求到指定的 URL
      並指定 'Content-Type': 'application/x-www-form-urlencoded' 標頭以指示伺服器使用 URL 編碼形式解析參數
      grant_type：指定授權類型為 "authorization_code"
      code：授權碼，這個值是從 code 變數中取得的
      redirect_url：指定用戶授權完成後的重定向 URL
      client_id：用於識別應用程式的客戶端 ID
      client_secret：應用程式的客戶端密鑰
      這些參數使用 qs.stringify 函式轉換為 URL 編碼的形式，以符合 "application/x-www-form-urlencoded" 的請求格式
      Content-Type': 'application/x-www-form-urlencoded'：指定請求的內容類型為 URL 編碼形式
      */
    const tokenResponse = await axios.post(
      "https://api.line.me/oauth2/v2.1/token",
      qs.stringify({
        grant_type: "authorization_code",
        code: code,
        // yourURI 請設置為實際Line developer 設定的重新導向網址
        redirect_uri: redirect_uri,
        client_id: client_id,
        client_secret: client_secret,
      }),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );

    /*
      從 tokenResponse 的回應資料中取得 access_token 和 id_token。
      這些欄位是從 LINE 登入 API 取得的授權資訊。
      access_token 是用來作為驗證的令牌
      id_token 是使用者的身份令牌。
      */
    const accessToken = tokenResponse.data.access_token;
    const idToken = tokenResponse.data.id_token;
    console.log("accessToken");
    console.log(accessToken);
    console.log("idToken");
    console.log(idToken);

    /*
      使用 Axios 發送 HTTP POST 到 https://api.line.me/oauth2/v2.1/verify，驗證 id_token 以獲取包含使用者資訊的回應
      id_token：用於識別使用者的身份令牌
      client_id：用於識別應用程式的客戶端 ID
      使用 qs.stringify 函式轉換為 URL 編碼的形式，以符合 "application/x-www-form-urlencoded" 的請求格式
      Content-Type': 'application/x-www-form-urlencoded'：指定請求的內容類型為 URL 編碼形式。
      'Authorization': 'Bearer ' + accessToken：使用存取令牌進行身份驗證，將存取令牌放在 'Bearer ' 字符串之後。
      */
    const userInfoResponse = await axios.post(
      "https://api.line.me/oauth2/v2.1/verify",
      qs.stringify({
        id_token: idToken,
        client_id: client_id,
      }),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + accessToken,
        },
      }
    );

    /*
      根據需求，可以在登入後的處理中進行相應的操作，例如驗證用戶資訊、儲存登入狀態等。
      */
    const lineUserId = userInfoResponse.data.sub;
    const lineNickname = userInfoResponse.data.name;
    const lineNickemail = userInfoResponse.data.email;
    const linePicture = userInfoResponse.data.picture;
    console.log(userInfoResponse.data);
    console.log(lineUserId);
    console.log(lineNickname);
    console.log("lineNickemail")
    console.log(lineNickemail);
    console.log(linePicture);

    // 確認 LINE 登入後的使用者資訊是否有 email，若無 email 則不進行智勝網的註冊
    if ((lineNickemail === 'null') || (typeof (lineNickemail) === "undefined") || (lineNickemail === "")) {
      Vue.swal({
        title: 'LINE 尚未綁定 email',
        text: '請先將 LINE 綁定 email 後再進行快速註冊及登入',
        icon: 'error',
        confirmButtonText: '確認'
      });
    }

    userSignUp({
      parent_id: 3,
      name: lineNickname,
      password: "",
      gender: "",
      phone: "",
      email: lineNickemail,
      dealer_number: "3",
      //birthday: new Date(1981, 1, 1, 0, 0, 0), //Fri Apr 15 1988 10:22:15
      birthday: "",
      address: "",
      bank_code: 0,
      bank_account: 0,
      identity: "",
      timeout: 1000,
      signup_type: "LINE",
      line_uid: lineUserId,
      line_picture_link: linePicture,
    })
      .then((response) => {
        console.log(response);
        const errorCode = response.data.error_code;
        console.log(errorCode);

        if (errorCode === "") {
          userLogin({
            email: lineNickemail,
            password: "",
            login_type: "LINE"
          })
            .then((response) => {
              console.log(response);
              const errorCode = response.data.error_code;
              console.log(errorCode);

              if (errorCode === "") {
                const auth = response.data.token;
                //this.$store.commit('setAuth', this.auth);
                Cookie.set("auth", auth);
                console.log(auth);

                var tokenParts = auth.split(".");
                var body = JSON.parse(atob(tokenParts[1]));
                console.log("token body");
                console.log(body);
                console.log(body.sub);

                setTimeout(() => {
                  window.location.reload();
                }, 200);
              } else {
                console.log("test123")
                //this.alertError = true;
              }
            })
            .catch(function (error) {
              // 請求失敗處理
              console.log(error);
            });
        } else {
          console.log("test123")
          //this.alertError = true;
          //setTimeout(() => {
          //  this.$router.go();
          //}, this.timeout);
        }
      })
      .catch(function (error) {
        // 請求失敗處理
        console.log(error);
      });

  } catch (error) {
    console.log(error);
  }
})();

export default {
  name: "SettingsPhone",
  components: {
    MarqueeText
  },
  data() {
    return {
      userId: 1,
      switche: true,
      name: "",
      role: "",
      line_picture_link: "",
      robot_enabled: 0,
      phone: "",
      isLogin: 0,
      cards: [
        {
          image: require("@/assets/img/products/01_bingoex.png"),
          nextPageLink: "/games/new-bingo",
        },
        {
          image: require("@/assets/img/products/02_wg.png"),
          nextPageLink: "/games/new-wg-lottery-ar",
        },
        {
          image: require("@/assets/img/products/03_539_develop.png"),
          nextPageLink: "#",
        },
        {
          image: require("@/assets/img/products/06_dg-baccarat_maintain.png"),
          nextPageLink: "#",
        },
        {
          image: require("@/assets/img/products/05_sports-predictions_develop.png"),
          nextPageLink: "#",
        },
        {
          image: require("@/assets/img/products/04_electronic-game_develop.png"),
          nextPageLink: "#",
        },
      ],
      msg: "⟢⟢ 智勝歡迎您 ⟣⟣ 親愛的會員您好！若對官網有任何問題，請添加官方LINE帳號☞ LINE官方帳號: @328ihxms。為保障公平遊戲原則，我們將嚴格監控會員行為。若發現違規、欺騙、代操套利等行為，我們保留審核的權利。⚠️ 我們不提供代操服務，請勿將帳號密碼交由他人操作，避免個資外洩 ⚠️  ",
      intervalId: null //data 定義一個定時器id

    };
  },
  mounted() {
    //this.marquee()//啟動跑馬燈
    const token = Cookie.get('auth')

    console.log(token)
    console.log(typeof token)
    console.log((token === 'null') || (typeof (token) === "undefined"))
    if ((token === 'null') || (typeof (token) === "undefined")) {
      //this.$router.push('/homepage');
      console.log("token is null")
      console.log(token)
      this.isLogin = 0
    }
    else {
      this.isLogin = 1
      const tokenParts = token.split('.')
      console.log(tokenParts)
      const body = JSON.parse(atob(tokenParts[1]))
      console.log(body)
      console.log(body.sub)

      this.userId = body.sub

      userData(this.userId)
        .then(response => {
          this.repsData = response.data;
          console.log(this.repsData)

          this.name = this.repsData.name
          this.address = this.repsData.address
          this.gender = this.repsData.gender === 2 ? "女性" : "男性" //this.genders[this.repsData.gender - 1]
          this.phone = this.repsData.phone
          this.email = this.repsData.email
          this.email_pass_status = this.repsData.email_pass_status
          this.phone_pass_status = this.repsData.phone_pass_status
          this.role = this.repsData.role
          this.due_date = this.repsData.due_date
          this.birthday = this.repsData.birthday
          this.bank_code = this.repsData.bank_code
          this.bank_account = this.repsData.bank_account
          this.identity = this.repsData.identity
          this.line_notify_token = this.repsData.line_notify_token
          this.robot_enabled = this.repsData.robot_enabled

          if (this.phone === "" || this.phone_pass_status === 0 || this.birthday === "" || this.gender === "" || this.address === "" || this.line_notify_token === "") {
            if (this.phone === "" || this.phone_pass_status === 0) {
              this.$router.push("/profile-settings/phone");
            } else {

              this.$router.push("/profile-settings");
            }
          } else {
            console.log("homepage");
            //this.isShowProgress = true;
            //setTimeout(() => {
            //  this.isShowProgress = false;
            //  this.$router.push("/homepage");
            //}, 1000);
          }

          // 賓果EX特殊處理，只有特定帳號可以看到 BingoBingo 設定功能頁面
          //if (this.email === "justin-1380@hotmail.com" || this.email === "jolin120651@gmail.com" || this.email === "ialy1234@hotmail.com") {
          //  this.cards[0].nextPageLink = "/games/new-bingo-bingo"
          //}

        })
        .catch(error => { // 请求失败处理
          console.log(error);
        });

    }


  },
  methods: {
    login() {
      this.$router.push('/login');
    },
    action1() {
      this.$router.go();
    },
    action2() {
      this.$router.push('/disclaimer');
    },
    action3() {
      this.$router.push('/service-description');
    },
    action4() {
      this.$router.push('/lottery-area');
    },
    action5() {
      this.$router.push('/profile');
    },
    verifyRobotOperation(nextPageLink) {
      console.log("verifyRobotOperation")
      console.log(nextPageLink)

      const token = Cookie.get("auth");
      console.log(token);
      console.log(typeof token);
      console.log(token === "null" || typeof token === "undefined");
      if (token === "null" || typeof token === "undefined") {
        this.$router.push("/login");
      } else {
        const tokenParts = token.split(".");
        console.log(tokenParts);
        const body = JSON.parse(atob(tokenParts[1]));
        console.log(body);
        console.log(body.sub);
        this.userId = body.sub

        if (this.robot_enabled === 0) {
          this.$swal({
            title: "尚未開通自動下注機器人",
            //text: "您確認要停止自動下注且刪除代理機器人！",
            icon: "warning",
            //showCancelButton: true,
            confirmButtonText: "確認",
            //cancelButtonText: "否",
            customClass: {
              confirmButton: "btn bg-gradient-success",
              //cancelButton: "btn bg-gradient-danger",
            },
          }).then((result) => {
            if (result.isConfirmed) {
              this.$swal.fire({
                title: "前往會員資訊截圖回傳至官方LINE客服",
                text: "前往會員資訊，截圖會員資訊頁面，回傳給官方LINE客服以利開通自動下注機器人。",
                icon: "success",
                confirmButtonText: "確認",
              }).then((result) => {
                if (result.isConfirmed) {
                  this.$router.push("/profile");
                }
              })
            }
          });

        } else {
          jobGetStatus(this.userId)
            .then((response) => {
              console.log(response);
              this.repsMessage = response.data.message;
              const jobStatus = response.data.job_status;
              console.log("jobStatus");
              console.log(jobStatus);

              // jobStatus = 'undefined' 代表沒有在運行的機器人
              if (typeof jobStatus === 'undefined') {
                this.$router.push(nextPageLink)
              } else {
                this.$swal({
                  title: "AI機器人正在執行中",
                  //text: "您確認要停止自動下注且刪除代理機器人！",
                  icon: "warning",
                  //showCancelButton: true,
                  confirmButtonText: "確認",
                  //cancelButtonText: "否",
                  customClass: {
                    confirmButton: "btn bg-gradient-success",
                    //cancelButton: "btn bg-gradient-danger",
                  },
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.$swal.fire({
                      title: "前往AI機器人管理",
                      text: "前往機器人管理，可停止機器人，重新設定遊戲參數再次啟動AI機器人。",
                      icon: "success",
                      confirmButtonText: "確認",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        this.$router.push("/robot-agent-overview");
                      }
                    })
                  }
                });
              }
            })
            .catch(function (error) {
              // 請求失敗處理
              console.log(error);
            });
        }
      }
    },
    marquee() {
      if (this.intervalId != null) return;

      this.intervalId = setInterval(() => {
        //得到第一個字元
        var start = this.msg.substring(0, 1)
        //得到最後一個字元
        var end = this.msg.substring(1)
        //後面與前面字元連接
        this.mag = end + start
      }, 100)
    },
    /*
    //停止計時器     
    stop(){
      clearInterval(this.intervalId)
      //當清除定時器之後，重新讓intervalId為null
      this.intervalId = null;
    }
    */
  },
};
</script>
<style scoped>
.footer-buttons {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  background-color: #ffffff;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  padding: 0px 0;
}

.footer-buttons button {
  flex: 1;
  margin: 0 5px;
  padding: 10px;
  font-size: 16px;
  border: none;
  background-color: #ffffff;
  color: white;
  border-radius: 5px;
}

.footer-buttons button:hover {
  background-color: #aa00b373;
}

@media (max-width: 768px) {
  .footer-buttons button {
    font-size: 14px;
    padding: 8px;
  }
}
</style>
