import Vue from "vue";
import VueRouter from "vue-router";
import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import DashboardLayoutNotMenu from "../views/Layout/DashboardLayoutNotMenu.vue";
import ProfileLayout from "../views/Layout/ProfileLayout.vue";
import DashboardLayoutVr from "../views/Layout/DashboardLayoutVr.vue";
import PageLayout from "../views/Layout/PageLayout";
import HomePageLayout from "../views/Layout/HomePageLayout";
import AuthBasicLayout from "../views/Layout/AuthBasicLayout";
import AuthSignUpLayout from "../views/Layout/AuthSignUpLayout";
import AuthCoverLayout from "../views/Layout/AuthCoverLayout";
import AuthLoginLayout from "../views/Layout/AuthLoginLayout";
import AuthIllustrationLayout from "../views/Layout/AuthIllustrationLayout";
import store from "@/store";

// Dashboard pages
const Dashboard = () => import("../views/Dashboard/Dashboard.vue");
const Discover = () => import("../views/Dashboard/Discover.vue");
const Automotive = () => import("../views/Dashboard/Automotive.vue");
const Sales = () => import("../views/Dashboard/Sales.vue");
const SmartHome = () => import("../views/Dashboard/SmartHome.vue");
const VrDefault = () => import("../views/Dashboard/VrDefault.vue");
const VrInfo = () => import("../views/Dashboard/VrInfo.vue");
const Crm = () => import("../views/Dashboard/Crm.vue");
const GettingStarted = () => import("../views/Dashboard/GettingStarted.vue");
const Disclaimer = () => import("../views/Dashboard/Disclaimer.vue");
const ServiceDescription = () =>
  import("../views/Dashboard/ServiceDescription.vue");
const LotteryArea = () => import("../views/Dashboard/LotteryArea.vue");
const LotteryAreaBingoBingo = () =>
  import("../views/Dashboard/LotteryAreaBingoBingo.vue");
const LotteryAreaM539 = () => import("../views/Dashboard/LotteryAreaM539.vue");
const LotteryAreaWg = () => import("../views/Dashboard/LotteryAreaWg.vue");
// Pages
const Pricing = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Pricing.vue");
const Rtl = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Rtl.vue");
const ProfileOverview = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Profile/ProfileOverview.vue"
  );
const Messages = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Profile/Messages.vue");
const Projects = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Profile/Projects.vue");
const RobotAgentOverview = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Profile/RobotAgentOverview.vue"
  );
const Reports = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Users/Reports.vue");
const NewUser = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Users/NewUser.vue");
const Homepage = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Account/Homepage.vue");
const Settings = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Account/Settings.vue");
const SettingsPhone = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Account/SettingsPhone.vue"
  );
const SettingsVerifyPhone = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Account/SettingsVerifyPhone.vue"
  );
const M539Settings = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Account/M539Settings.vue"
  );
const Dealer = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Account/Dealer.vue");
const Billing = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Account/Billing.vue");
const Invoice = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Account/Invoice.vue");
const Groups = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Account/Groups.vue");
const Timeline = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Projects/Timeline.vue");
const Charts = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Charts.vue");
const Alerts = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Alerts.vue");
const Notifications = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Notifications.vue");

const HomePage = () =>
  import(
    /* webpackChunkName: "pages" */
    "@/views/Pages/Authentication/SignUp/HomePage.vue"
  );
const SignUpBasic = () =>
  import(
    /* webpackChunkName: "pages" */
    "@/views/Pages/Authentication/SignUp/Basic.vue"
  );

const SignUp = () =>
  import(
    /* webpackChunkName: "pages" */
    "@/views/Pages/Authentication/SignUp/SignUp.vue"
  );

const SignUpCover = () =>
  import(
    /* webpackChunkName: "pages" */
    "@/views/Pages/Authentication/SignUp/Cover.vue"
  );
const SignUpLogin = () =>
  import(
    /* webpackChunkName: "pages" */
    "@/views/Pages/Authentication/SignUp/Login.vue"
  );
const SignUpForgetPassword = () =>
  import(
    /* webpackChunkName: "pages" */
    "@/views/Pages/Authentication/SignUp/ForgetPassword.vue"
  );
const ResetPassword = () =>
  import(
    /* webpackChunkName: "pages" */
    "@/views/Pages/Authentication/SignUp/ResetPassword.vue"
  );
const Verify = () =>
  import(
    /* webpackChunkName: "pages" */
    "@/views/Pages/Authentication/SignUp/Verify.vue"
  );
const VerifyEmail = () =>
  import(
    /* webpackChunkName: "pages" */
    "@/views/Pages/Authentication/SignUp/VerifyEmail.vue"
  );
const VerifyPhone = () =>
  import(
    /* webpackChunkName: "pages" */
    "@/views/Pages/Authentication/SignUp/VerifyPhone.vue"
  );
const SignUpIllustration = () =>
  import(
    /* webpackChunkName: "pages" */
    "@/views/Pages/Authentication/SignUp/Illustration.vue"
  );

// Applications
const Kanban = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Applications/Kanban.vue");
const Wizard = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Applications/Wizard.vue");
const Datatables = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Applications/Datatables.vue");
const MemberManage = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Applications/MemberManage.vue"
  );
const Bonus = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Applications/Bonus.vue");
const Calendar = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Applications/Calendar.vue");

// Ecommerce
const NewBingo = () =>
  import(
    /* webpackChunkName: "pages" */
    "@/views/Ecommerce/Products/NewBingo.vue"
  );

const NewBingoBingo = () =>
  import(
    /* webpackChunkName: "pages" */
    "@/views/Ecommerce/Products/NewBingoBingo.vue"
  );
const NewWgLotteryAutomobileRace = () =>
  import(
    /* webpackChunkName: "pages" */
    "@/views/Ecommerce/Products/NewWgLotteryAutomobileRace.vue"
  );
const NewProduct = () =>
  import(
    /* webpackChunkName: "pages" */
    "@/views/Ecommerce/Products/NewProduct.vue"
  );
const EditProduct = () =>
  import(
    /* webpackChunkName: "pages" */
    "@/views/Ecommerce/Products/EditProduct.vue"
  );
const ProductPage = () =>
  import(
    /* webpackChunkName: "pages" */
    "@/views/Ecommerce/Products/ProductPage.vue"
  );
const OrderList = () =>
  import(
    /* webpackChunkName: "pages" */
    "@/views/Ecommerce/Orders/OrderList.vue"
  );
const OrderVerifyList = () =>
  import(
    /* webpackChunkName: "pages" */
    "@/views/Ecommerce/Orders/OrderVerifyList.vue"
  );
const OrderYearList = () =>
  import(
    /* webpackChunkName: "pages" */
    "@/views/Ecommerce/Orders/OrderYearList.vue"
  );
const OrderListOwn = () =>
  import(
    /* webpackChunkName: "pages" */
    "@/views/Ecommerce/Orders/OrderListOwn.vue"
  );
const OrderDetails = () =>
  import(
    /* webpackChunkName: "pages" */
    "@/views/Ecommerce/Orders/OrderDetails.vue"
  );

Vue.use(VueRouter);

let vrPages = {
  path: "/",
  component: DashboardLayoutVr,
  name: "Vr",
  children: [
    {
      path: "/pages/dashboards/vr/vr-default",
      name: "VrDefault",
      component: VrDefault,
      meta: {
        groupName: "Dashboards",
      },
    },
    {
      path: "/pages/dashboards/vr/vr-info",
      name: "VrInfo",
      component: VrInfo,
      meta: {
        groupName: "Dashboards",
      },
    },
  ],
};

let profilePages = {
  path: "/",
  component: ProfileLayout,
  name: "Profile",
  children: [
    /*
    {
      path: "/profile",
      //path: "/ac/profile",
      name: "ProfileOverview",
      component: ProfileOverview,
      meta: {
        groupName: "Pages",
      },
    },*/
    {
      path: "/pages/pages/profile/messages",
      name: "Messages",
      component: Messages,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/profile/projects",
      name: "Project",
      component: Projects,
      meta: {
        groupName: "Pages",
      },
    },
    /*
    {
        path: "/robot-agent-overview",
        name: "RobotAgentOverview",
        component: RobotAgentOverview,
        meta: {
          groupName: "Pages",
        },
      },
      */
  ],
};

let userPages = {
  path: "/",
  component: DashboardLayout,
  name: "Users",
  children: [
    {
      path: "/pages/pages/users/reports",
      name: "Reports",
      component: Reports,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/users/new-user",
      name: "NewUser",
      component: NewUser,
      meta: {
        groupName: "Pages",
      },
    },
  ],
};

let oobePages = {
  path: "/",
  component: DashboardLayoutNotMenu,
  name: "AccountNotMenu",
  children: [
    {
      path: "/profile-settings",
      name: "Settings",
      component: Settings,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/profile-settings/phone",
      name: "SettingsPhone",
      component: SettingsPhone,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/profile-settings/verify-phone",
      name: "SettingsVerifyPhone",
      component: SettingsVerifyPhone,
      meta: {
        groupName: "Pages",
      },
    },
  ],
};

let accountPages = {
  path: "/",
  component: DashboardLayout,
  name: "Account",
  children: [
    {
      path: "/homepage",
      name: "ProfilHomepageeOverview",
      component: Homepage,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/profile",
      name: "ProfileOverview",
      component: ProfileOverview,
      meta: {
        groupName: "Pages",
      },
    },
    /*
    {
      path: "/profile-bk", 
      name: "Settings",
      component: Settings,
      meta: {
        groupName: "Pages",
      },
    },*/
    {
      path: "/robot-agent-overview",
      name: "RobotAgentOverview",
      component: RobotAgentOverview,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/account/m539-settings",
      name: "M539Settings",
      component: M539Settings,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/account/billing",
      name: "Billing",
      component: Billing,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/account/invoice",
      name: "Invoice",
      component: Invoice,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/account/groups",
      name: "Groups",
      component: Groups,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/account/dealer",
      name: "Dealer",
      component: Dealer,
      meta: {
        groupName: "Pages",
      },
    },
  ],
};

let projectsPages = {
  path: "/",
  component: DashboardLayout,
  name: "Projects",
  children: [
    {
      path: "/pages/pages/projects/timeline",
      name: "Timeline",
      component: Timeline,
      meta: {
        groupName: "Pages",
      },
    },
  ],
};

let applicationPages = {
  path: "/",
  component: DashboardLayout,
  name: "Application",
  children: [
    {
      path: "/pages/applications/kanban",
      name: "Kanban",
      component: Kanban,
      meta: {
        groupName: "Applications",
      },
    },
    {
      path: "/pages/applications/wizard",
      name: "Wizard",
      component: Wizard,
      meta: {
        groupName: "Applications",
      },
    },
    {
      path: "/pages/applications/datatables",
      name: "Datatables",
      component: Datatables,
      meta: {
        groupName: "Applications",
      },
    },
    {
      path: "/pages/applications/member-manage",
      name: "MemberManage",
      component: MemberManage,
      meta: {
        groupName: "Applications",
      },
    },
    {
      path: "/pages/applications/bonus",
      name: "Bonus",
      component: Bonus,
      meta: {
        groupName: "Applications",
      },
    },
    {
      path: "/pages/applications/calendar",
      name: "Calendar",
      component: Calendar,
      meta: {
        groupName: "Applications",
      },
    },
  ],
};

let pricingPage = {
  path: "/",
  component: PageLayout,
  name: "Pricing Page",
  children: [
    {
      path: "/pages/pages/pricing-page",
      name: "Pricing",
      component: Pricing,
    },
  ],
};

let homePage = {
  path: "/",
  component: HomePageLayout,
  name: "Home Page",
  children: [
    {
      path: "/home",
      name: "HomePage",
      component: HomePage,
    },
  ],
};

let authBasicPages = {
  path: "/",
  component: AuthBasicLayout,
  name: "Authentication Basic",
  children: [
    {
      path: "/signup-basic",
      name: "SignUpBasic",
      component: SignUpBasic,
    },
  ],
};

let authSignUpPages = {
  path: "/",
  component: AuthSignUpLayout,
  name: "Authentication SignUp",
  children: [
    {
      path: "/signup-bk",
      name: "SignUp",
      component: SignUp,
    },
  ],
};

let authCoverPages = {
  path: "/",
  component: AuthCoverLayout,
  name: "Authentication Cover",
  children: [
    {
      path: "/pages/authentication/signup/cover",
      //path: "/ac/signup",
      name: "SignUpCover",
      component: SignUpCover,
    },
  ],
};

let authLoginPages = {
  path: "/",
  component: AuthLoginLayout,
  name: "Authentication Login",
  children: [
    {
      path: "/login",
      //path: "/ac/login",
      name: "SignUpLogin",
      component: SignUpLogin, //SignUpLogin,SignUpBasic
    },
  ],
};

let authForgetPasswordPages = {
  path: "/",
  component: AuthLoginLayout,
  name: "Authentication Forget Password",
  children: [
    {
      path: "/pages/authentication/signup/forget-password",
      //path: "/ac/forget-password",
      name: "SignUpForgetPassword",
      component: SignUpForgetPassword,
    },
  ],
};

let authResetPasswordPages = {
  path: "/",
  component: AuthLoginLayout,
  name: "Authentication Reset Password",
  children: [
    {
      path: "/pages/authentication/reset-password",
      //path: "/ac/forget-password",
      name: "ResetPassword",
      component: ResetPassword,
    },
  ],
};

let authVerifyPages = {
  path: "/",
  component: AuthLoginLayout,
  name: "Authentication Verify",
  children: [
    {
      path: "/pages/authentication/verify",
      //path: "/ac/forget-password",
      name: "Verify",
      component: Verify,
    },
  ],
};

let authVerifyEmailPages = {
  path: "/",
  component: AuthLoginLayout,
  name: "Authentication Verify Email",
  children: [
    {
      path: "/pages/authentication/verify-email",
      //path: "/ac/forget-password",
      name: "VerifyEmail",
      component: VerifyEmail,
    },
  ],
};

let authVerifyPhonePages = {
  path: "/",
  component: AuthLoginLayout,
  name: "Authentication Verify Phone",
  children: [
    {
      path: "/verify-phone",
      //path: "/ac/forget-password",
      name: "VerifyPhone",
      component: VerifyPhone,
    },
  ],
};

let authIllustrationPages = {
  path: "/",
  component: AuthIllustrationLayout,
  name: "Authentication Illustration",
  children: [
    {
      path: "/pages/authentication/signup/illustration",
      name: "SignUpIllustration",
      component: SignUpIllustration,
    },
  ],
};

const routes = [
  {
    path: "/",
    name: "Dashboard",
    redirect: "/homepage",
    component: DashboardLayout,
    /*
        beforeEnter(to, from, next) {
            console.log("store.getters.isAuthenticated")
            console.log(store.getters.isAuthenticated)
            if (!store.getters.isAuthenticated) {
                next('/pages/authentication/signin/login')
            } else {
                next()
            }
        },
        */
    children: [
      {
        path: "pages/dashboards/analytics",
        name: "Analytics",
        component: Dashboard,
        meta: {
          groupName: "Dashboards",
        },
      },
      {
        path: "pages/dashboards/discover",
        name: "Discover",
        component: Discover,
        meta: {
          groupName: "Dashboards",
        },
      },
      {
        path: "/pages/dashboards/smart-home",
        name: "SmartHome",
        component: SmartHome,
        meta: {
          groupName: "Dashboards",
        },
      },
      {
        path: "/pages/dashboards/crm",
        name: "Crm",
        component: Crm,
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "/pages/dashboards/automotive",
        name: "Automotive",
        component: Automotive,
        meta: {
          groupName: "Dashboards",
        },
      },
      {
        path: "/pages/dashboards/sales",
        name: "Sales",
        component: Sales,
        meta: {
          groupName: "Dashboards",
        },
      },
      {
        path: "/pages/pages/rtl",
        name: "RTL",
        component: Rtl,
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "/pages/pages/charts",
        name: "Charts",
        component: Charts,
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "/pages/pages/alerts",
        name: "Alerts",
        component: Alerts,
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "/pages/pages/notifications",
        name: "Notifications",
        component: Notifications,
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "getting-started",
        name: "Getting Started",
        component: GettingStarted,
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "/lottery-area",
        name: "Lottery Area",
        component: LotteryArea,
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "/lottery-area/bingo-bingo",
        name: "Lottery Area Bingo Bingo",
        component: LotteryAreaBingoBingo,
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "/lottery-area/m539",
        name: "Lottery Area M539",
        component: LotteryAreaM539,
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "/lottery-area/wg",
        name: "Lottery Area WG",
        component: LotteryAreaWg,
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "/service-description",
        name: "Service Description",
        component: ServiceDescription,
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "/disclaimer",
        name: "Disclaimer",
        component: Disclaimer,
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "/games/new-bingo",
        name: "NewBingo",
        component: NewBingo,
        meta: {
          groupName: "Ecommerce",
        },
      },
      {
        path: "/games/new-bingo-bingo",
        name: "NewBingoBingo",
        component: NewBingoBingo,
        meta: {
          groupName: "Ecommerce",
        },
      },
      {
        path: "/games/new-wg-lottery-ar",
        name: "NewWgLotteryAutomobileRace",
        component: NewWgLotteryAutomobileRace,
        meta: {
          groupName: "Ecommerce",
        },
      },
      {
        path: "/pages/ecommerce/products/new-product",
        name: "NewProduct",
        component: NewProduct,
        meta: {
          groupName: "Ecommerce",
        },
      },
      {
        path: "/pages/ecommerce/products/edit-product",
        name: "EditProduct",
        component: EditProduct,
        meta: {
          groupName: "Ecommerce",
        },
      },
      {
        path: "/pages/ecommerce/products/product-page",
        name: "ProductPage",
        component: ProductPage,
        meta: {
          groupName: "Ecommerce",
        },
      },
      {
        path: "/pages/ecommerce/orders/list",
        name: "OrderList",
        component: OrderList,
        meta: {
          groupName: "Ecommerce",
        },
      },
      {
        path: "/pages/ecommerce/orders-verify/list",
        name: "OrderVerifyList",
        component: OrderVerifyList,
        meta: {
          groupName: "Ecommerce",
        },
      },
      {
        path: "/pages/ecommerce/orders-year/list",
        name: "OrderYearList",
        component: OrderYearList,
        meta: {
          groupName: "Ecommerce",
        },
      },
      {
        path: "/pages/ecommerce/orders/list/own",
        name: "OrderListOwn",
        component: OrderListOwn,
        meta: {
          groupName: "Ecommerce",
        },
      },
      {
        path: "/pages/ecommerce/orders/details",
        name: "OrderDetails",
        component: OrderDetails,
        meta: {
          groupName: "Ecommerce",
        },
      },
    ],
  },
  pricingPage,
  profilePages,
  applicationPages,
  userPages,
  oobePages,
  accountPages,
  projectsPages,
  vrPages,
  homePage,
  authBasicPages,
  authSignUpPages,
  authCoverPages,
  authLoginPages,
  authForgetPasswordPages,
  authResetPasswordPages,
  authVerifyPages,
  authVerifyEmailPages,
  authVerifyPhonePages,
  authIllustrationPages,
];

const router = new VueRouter({
  //mode: 'history',
  routes,
});

export default router;
